@use "~styles" as *;

.custom404 {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: em(32);

  @include mdxl {
    margin-top: em(40);
  }

  &__title {
    margin-bottom: em(12);
    margin-top: em(12);

    @include mdxl {
      margin-bottom: em(16);
      margin-top: em(16);
    }
  }

  &__logo {
    position: relative;
    width: em(128);
    height: em(128);

    @include mdxl {
      width: em(200);
      height: em(200);
    }
  }

  &__button {
    margin-top: em(32);
    margin-bottom: em(40);

    @include mdxl {
      margin-top: em(40);
      margin-bottom: em(60);
    }
  }
}
